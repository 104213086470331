import React from 'react';
import './components.css';
import Phone from './media/phone.png'

const NavContactInfo = () => {
	return (
		<div className='sub-nav-properties nav-contact'>
			<span className='nav-contact-info'>
			<a href="tel:+77272273734">
			  {/* <img src={Phone} className="phone-icon"/> */}
			  +7 727 227 37 34 
			</a>
			</span>
		</div>
)
};

export default NavContactInfo; 
