import React from 'react';
import './Navbar.css';
import NavLogo from './components/NavLogo'
import NavSpan from './components/NavSpan'
import NavContactInfo from './components/NavContactInfo'
import { Link } from 'react-router-dom'

// const Navbar = (props) => {
// 	return (
// 		<div className='nav-container'>
// 			<NavLogo />
// 			<Link to='/faberlic'><NavSpan name='Фаберлик' /></Link>
// 			<Link to='/tracking'><NavSpan name='Отслеживание' /></Link>
// 			<Link to='/services'><NavSpan name='Услуги' /></Link>
// 			<Link to='/contacts'><NavSpan name='Контакты' /></Link>
// 			<NavContactInfo />
// 		</div>
// )
// };

// export default Navbar; 

const Navbar = (props) => {
	return (
		<nav className="navbar navbar-expand-lg navbar-light">
  			<div className="container-fluid">
    			<NavLogo />
    			<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      				<span className="navbar-toggler-icon"></span>
    			</button>
    			<div className="collapse navbar-collapse" id="navbarSupportedContent">
      				<ul className="navbar-nav me-auto mb-2 mb-lg-0">
        				<li className="nav-item">
          					{/* <Link to='/faberlic'><NavSpan name='Faberlic' /></Link> */}
        				</li>

						<li className="nav-item">
          					{/* <Link to='/flp'><NavSpan name='Forever Living' /></Link> */}
        				</li>

        				<li className="nav-item">
		  					<Link to='/tracking'><NavSpan name='Отслеживание' /></Link>
        				</li>

        				<li className="nav-item">
		  					<Link to='/services'><NavSpan name='Услуги' /></Link>
        				</li>

						<li className="nav-item">
		  					<Link to='/contacts'><NavSpan name='Контакты' /></Link>
        				</li>

						<li className="nav-item">
		  					<NavContactInfo />
        				</li>

      				</ul>
    			</div>
  			</div>
		</nav>

	)
};

export default Navbar; 



