import React from 'react';
import './Partner.css';
import PartnerSub from './components/PartnerSub'

const Partner = () => {
	return (
		<div className='partner-container'>
		  <PartnerSub />
		</div>
)
};

export default Partner; 
