import React, { Component } from 'react';
import './Footer.css';

const Footer = () => {
	return (
		<div className='footer-container'>
            <span>ТОО «POSTMAN» © {(new Date().getFullYear())}</span>
		</div>
)
};

export default Footer;
