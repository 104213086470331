import React, { Component } from 'react';
import './ContactMain.css';

const ContactMain = () => {
	return (
		<div className='contact-main-container'>

			<div className='contact-p-req'>
				<div>
				  <h2>Головной офис</h2>	
				  <a href="https://yandex.ru/maps/?um=constructor%3A602677cecf2f04d7f80ad25cdacaf6287a2c36b89b5f073b543ff18f7ef2921c&amp;source=constructorStatic" target="_blank"><img className="contact-map" src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A602677cecf2f04d7f80ad25cdacaf6287a2c36b89b5f073b543ff18f7ef2921c&amp;width=548&amp;height=450&amp;lang=ru_RU" alt="" style={{border: 0}} /></a>
				</div>
				<h2>Наши контакты и реквизиты</h2>
				<p>Почтовый адрес: ул. Бродского, 37А, офис 205</p>
				<p>Юр.адрес: г. Алматы, ул. Кутузова, дом 70 А, почтовый индекс: 050007</p>
				<p>Тел. +7 (727) 227 37 34 (офис)</p>
				<p>Тел. +7 (727) 227 37 33 (бухгалтерия)</p>
				<p>Эл.адрес: postman_kz@mail.ru</p>
				<p>РНН 600 700 597 765</p>
				<p>БИН 071240016167</p>
				<p>IBAN: KZ 036017131000013801</p>
				<p>Расчетный счет в: АО «Народный Банк Казахстана»</p>
				<p>Директор Алимов Бакыт Хасанович</p>
				<p>ТОО «POSTMAN»</p>
			</div>
		</div>
)
};

export default ContactMain;

