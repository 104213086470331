import React, { Component } from 'react';
import Tracking from './Tracking/Tracking';
import './UniversalTrackingMain.css';


const TrackingMain = (props) => {
	return (
		<div className='tracking-main-container'>
			
			<h2 className="tracking-main-h2">Отслеживание отправлений</h2>
            {/* <h1>Трекинг</h1> */}
			<div className='tracking-container'>
            	<Tracking number={props.match.params.number} />
			</div>
		</div>
)
};

export default TrackingMain;