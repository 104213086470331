import React from 'react';
import './components.css';
import Group from './media/Group.png'
import Flp from './media/flp.png'

const PartnerSub = () => {
	return (
		<div className='partner-sub-container'>
			<p className="partner-sub-container-p-1">Наши партнеры</p>
				<div className="partner-img">
					<img className="faberlic_png" src={Group}/>
		  			<img className="flp_png" src={Flp}/>
				</div>
     		 <p className="partner-sub-container-p-2">Мы доставляем тысячи заказов по всему Казахстану</p>
		</div>
)
};

export default PartnerSub; 
