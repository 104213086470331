import React from 'react';
import Ionicon from 'react-ionicons';
import moment from 'moment';
// import moment from 'moment/min/moment-with-locales.min.js';
import axios from 'axios';

import 'moment/locale/ru';

import 'bootstrap-4-grid/css/grid.min.css'

import './Tracking.css';

moment.locale('ru')

class Tracking extends React.Component {

  constructor(props) {
    super(props)
    let history = []
    if(typeof window !== "undefined") {
      history = JSON.parse(localStorage.getItem("trackingHistory"))
    }
    if(Array.isArray(history) === true)
      this.state = { loading: false, error: false, code: props.number, destination: null, history: history }
    else
      this.state = { loading: false, error: false, code: props.number, destination: null, history: [] }
    if (props.number) {
      this.fetchDestination(this.state.code)
    }
  }

  fetchDestination = (code) => {
    const uri = `https://pman.work/api/public/v1/global_tracking/${code}`
    // const uri = `http://localhost:3000/api/public/v1/global_tracking/${code}`
    const history = Array.from(new Set([{ code: code, time: new Date() }, ...this.state.history])).slice(0, 5)
    this.setState({ loading: true, history: history })
    if(typeof window !== "undefined") {
      localStorage.setItem("trackingHistory", JSON.stringify(history));
    }
    axios.get(uri).then(res => {
      const json = res.data
      if (json.error !== undefined)
        this.setState({ loading: false, error: true, destination: null })
      else if (json.destination !== undefined)
        this.setState({ loading: false, error: false, destination: json.destination })
    });
  }

  handleChange = (val) => {
    this.setState({ code: val })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.code !== "")
      this.fetchDestination(this.state.code)
    this.setState({ code: "" })
  }

  handleSubmitFromHistory = (e, code) => {
    e.preventDefault()
    if (code !== "")
      this.fetchDestination(code)
    this.setState({ code: "" })
  }

  renderResult = () => {
    const { code, loading, error, destination } = this.state;
    if (loading !== false)
      return this.renderLoading();
    if (error !== false)
      return this.renderError();
    else if (destination === null)
      return this.renderInfo();
    else if (destination !== null)
      return this.renderDestination();
  }

  renderError = () => (
    <div className="tracking__error-box">
      <Ionicon icon="ios-warning-outline" color="red" fontSize="44px"/>
      <p>Накладная не существует или она еще не зарегистрирована в системе.</p>
      <p>Убедитесь, что номер введен полностью (все цифры и буквы присутствующие в накладной).</p>
      <p>Если номер указан верно, то накладная должна появиться в ближайшее время, как только ее зарегистрируют операторы нашей компании.</p>
    </div>    
  )

  renderLoading = () => (
    <div className="tracking__loading-box">
      <Ionicon icon="ios-sync" rotate color="#00509d" fontSize="44px"/>
    </div>    
  )

  renderInfo = () => (
    <div className="tracking__info-box">
      <Ionicon icon="ios-paper-outline" fontSize="35px"/>
      <h3>Введите номер отправления</h3>
      {/* <p>Введите номер отправления, указанный под штрих-кодом. Указывайте номер полностью. Обратите внимание на то, что номер накладной и номер отправления может отличаться.</p> */}
      <p>
        Для отслеживания вы можете использовать номер накладной или отправления. 
        Введите номер отправления или накладной, указанный под штрих-кодом. 
        Указывайте номер полностью. Обратите внимание на то, что номер накладной 
        и номер отправления может отличаться.
      </p>

      <p>
        Вы также можете использовать свой персональный код пункта выдачи с указанием рейса. 
        Введите номер отправления в формате 1647445-884, где 1647445 - это номер рейса, 
        а 884 ваш персональный код пункта выдачи.
      </p>
    </div>
  )

  renderDestination = () => {
    const { destination } = this.state;
    return (
      <div className="tracking__waybill-box">
        <h2>{destination.code}</h2>
        <h4>{destination.receiver}</h4>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <dd>Откуда</dd>
            <dt>{destination.origin_region}</dt>
            {/* <dt>{destination.orig_address}</dt> */}
          </div>
          <div className="col-sm-12 col-md-4">
            <dd>Куда</dd>
            <dt>{destination.destination_region}</dt>
            {/* <dt>{destination.dest_address}</dt> */}
          </div>
          <div className="col-sm-12 col-md-2">
            <dd>Кол-во</dd>
            <dt>{destination.quantity} шт</dt>
          </div>
          <div className="col-sm-12 col-md-2">
            <dd>Вес</dd>
            <dt>{destination.weight} кг</dt>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12 col-md-4">
            <dd>Дата доставки</dd>
            <dt>до {moment(destination.expiration).format('DD-MM-YYYY')}</dt>
          </div>
          {/* <div className="col-sm-12 col-md-4"></div> */}
          <div className="col-sm-12 col-md-6">
            <dd>Примечание</dd>
            <dt>{destination.notes ? destination.notes : "-"}</dt>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <hr />
            {this.renderDeliveryStatus(destination.deliveries)}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            {this.renderTracks(destination.destination_tracks)}
          </div>
        </div>
      </div>    
    )
  }

  renderTracks = (tracks) => {
    return(
      <div className="tracking__tracks">
        {tracks.map((track, idx) => <div className="tracking__track" key={idx}>{this.renderTrack(track)}</div>)}
      </div>
    )
  }

  renderTrack = (track) => (
    <div>
      <div className="tracking__track-info">
        <small className="tracking__track-time">{moment(track.created_at).calendar()}</small>
        <span className="tracking__track-code">
          {track.code}
        </span>
        <p className="tracking__track-description">
          {track.description}
          <br/>
          <small>
            {track.branch ? track.branch : ""}
          </small>
        </p>
      </div>
      <p className="tracking__track-description">
      {track.code == 'DESTINATION_DELIVERED' && <aside className='aside-text'><small>Указано время регистрации деталей доставки. Фактическое время доставки указано в блоке "Успешная доставка" выше.</small></aside> }
      </p>
    </div>
  )

  renderDeliveryStatus = (deliveries) => {
    if(Array.isArray(deliveries) && deliveries.length > 0) {
      const success = deliveries.filter(delivery => delivery.code === 'success')
      const failure = deliveries.filter(delivery => delivery.code === 'failure')
      if(success.length > 0) {
        return(
          <div>
            <h3 style={{ color: 'green' }}>
              Успешная доставка
            </h3>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <dd>Получил(а)</dd>
                <dt>{`${success[0].receiver_name}`}</dt>
              </div>
              <div className="col-sm-12 col-md-4">
                <dd>Должность</dd>
                <dt>{`${success[0].receiver_position}`}</dt>
              </div>
              <div className="col-sm-12 col-md-4">
                <dd>Время вручения</dd>
                <dt>{moment(success[0].stamp).calendar()}</dt>
              </div>
            </div>
            <br />
          </div>
        )
      }
      if (failure.length > 0) {
        return(
          <div>
            <h4 style={{ color: 'red' }}>
              Неудачные попытки доставки
            </h4>
            {failure.map((f, idx) => <p key={idx}>{idx + 1}) {f.code_description} <small>{moment(f.stamp).calendar()}</small></p>)}
          </div>
        )
      }
        
    }
    else {
      return (
        <div>
          <h3>
            В пути
          </h3>
          <p>
            Отправление(я) находятся в пути. Для уточнения деталей смотрите трекинг или свяжитесь с нами по телефону.
          </p>
        </div>
      )  
    }
  }

  renderHistory = () => {
    const { history } = this.state;
    if (history.length > 0) {
      return(
        <div>
          <h3>История запросов</h3>
          { history.map((x, idx) => <p key={idx}><small style={{float: 'right'}}>{moment(x.time).locale('ru').calendar()}</small><a href="#" onClick={(e) => this.handleSubmitFromHistory(e, x.code) }>{x.code}</a></p> ) }
        </div>
      )
    }
  }

  render() {
    return(
      <div className="tracking row tracking-container-width">
        <div className="col-md-4">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                onChange={(x) => this.handleChange(x.target.value)}
                value={this.state.code}
                className="tracking__input form-control"
                placeholder="BX00000001-1"
              />
            </div>
          </form>
          <div className="hide-history">
            {this.renderHistory()}
          </div>
        </div>
        <div className="col-md-8">
          {this.renderResult()}
        </div>
      </div>
    )
  }
}

export default Tracking;
