import React from 'react';
import AdvantageSub from './components/AdvantageSub';
import './About.css';

const About = () => {
	return (
		<div className=''>
			<AdvantageSub title='Большой парк автомобилей' text='У нас собственный автопарк грузовых и легковых машин, готовых выехать за грузом в любую минуту.'/>
			<AdvantageSub title='Детальное отслеживание' text='Трекинг ваших отправлений на каждом этапе транспортировки от отправителя к получателю.' />
			<AdvantageSub title='Обширная география' text='Крупные города, села и аулы? Мы доставим груз почти в любую точку Казахстана!'/>
		</div>
)
};

export default About; 
